// Sales Widget
import { Bar } from 'vue-chartjs'
import axios from 'axios';

export default ({
   extends: Bar,
   props: {

      statistic: {
         required: true,
      },

   },
   data: function () {
      return {
         color: {
            'primary': '#5D92F4',
            'warning': '#FFB70F',
            'danger': '#FF3739',
            'success': '#00D014',
            'info': '#00D0BD',
            'white': '#fff',
            'lightGrey': '#E8ECEE',
            'series1': '#ffe700',
            'series2': '#00d4bd',
            'series3': '#826bf8',
         },
         lineChartAxesColor: '#E9ECEF',
         legendFontColor: '#AAAEB3', // only works on react chart js 2
         chartGridColor: '#EAEAEA',
         axesColor: '#657786',
         shadowColor: 'rgba(0,0,0,0.3)',
         chartdata: null,
         options: {
            elements: {
               rectangle: {
                  borderWidth: 2,
                  borderSkipped: 'bottom',
               },
            },
            responsive: true,
            maintainAspectRatio: false,
            responsiveAnimationDuration: 500,
            legend: {
               display: false,
            },

            //    stacked: true,

            scales: {
               yAxes: [{
                  stacked: true,
                  ticks: {
                     display: true
                  },
                  gridLines: {
                     display: false,
                     drawBorder: false
                  }
               }],
               xAxes: [{
                  stacked: true,
                  ticks: {
                     padding: 10
                  },
                  barPercentage: 0.4,
                  categoryPercentage: 1.3,
                  gridLines: {
                     display: true,
                     drawBorder: false
                  }
               }]
            },




         }
      }
   },
   watch: {
      'statistic'() {
         //this.$emit('update:statistic',this.statistic)
         console.log("update");
         console.log(this.statistic.sum);
         this.renderChart({
            labels: ['-14', '15-19', '20-24', '25-29', '30-34', '35-39', '40-44', '45-49', '50+'],
            datasets: [
               {
                  type: 'bar',
                  label: 'Male',
                  backgroundColor: this.color.series1,
                  hoverBackgroundColor: this.color.series1,
                  borderWidth: 0,
                  data: this.statistic.male
               },
               {
                  type: 'bar',
                  label: 'Female',
                  backgroundColor: this.color.series2,
                  hoverBackgroundColor: this.color.series2,
                  borderWidth: 0,
                  data: this.statistic.female
               },
               {
                  type: 'bar',
                  label: 'Other',
                  backgroundColor: this.color.series3,
                  hoverBackgroundColor: this.color.series3,
                  borderWidth: 0,
                  data: this.statistic.other
               }
            ]
         }, this.options)

      }
   },
   mounted() {

      this.renderChart({
         labels: ['-14', '15-19', '20-24', '25-29', '30-34', '35-39', '40-44', '45-49', '50-'],
         datasets: [
            {
               type: 'bar',
               label: 'Male',
               backgroundColor: this.color.series1,
               hoverBackgroundColor: this.color.series1,
               borderWidth: 0,
               data: this.statistic.male
            },
            {
               type: 'bar',
               label: 'Female',
               backgroundColor: this.color.series2,
               hoverBackgroundColor: this.color.series2,
               borderWidth: 0,
               data: this.statistic.female
            },
            {
               type: 'bar',
               label: 'Other',
               backgroundColor: this.color.series3,
               hoverBackgroundColor: this.color.series3,
               borderWidth: 0,
               data: this.statistic.other
            }
         ]
      }, this.options)



   }
})
